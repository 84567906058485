var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.revenuesType.revenuesTypeImagePath,
            _vm.revenuesType.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.revenuesType.revenuesTypeImageIsDefault &&
          _vm.checkPrivilege(_vm.hasRevenuesTypeDeleteImage())},on:{"changeValue":function($event){_vm.revenuesType.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.revenuesType.fullCode,"title":_vm.$t('RevenuesTypes.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.revenuesType.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-revenuesTypeNameAr"),"errors":_vm.errors_revenuesTypeNameAr,"value":_vm.revenuesType.revenuesTypeNameAr,"title":_vm.$t('RevenuesTypes.nameAr'),"imgName":'revenuesTypes.svg'},on:{"changeValue":function($event){_vm.revenuesType.revenuesTypeNameAr = $event;
            _vm.$v.revenuesType.revenuesTypeNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-revenuesTypeNameEn"),"errors":_vm.errors_revenuesTypeNameEn,"value":_vm.revenuesType.revenuesTypeNameEn,"title":_vm.$t('RevenuesTypes.nameEn'),"imgName":'revenuesTypes.svg'},on:{"changeValue":function($event){_vm.revenuesType.revenuesTypeNameEn = $event;
            _vm.$v.revenuesType.revenuesTypeNameEn.$touch();}}}),_c('CustomInputFloat',{attrs:{"className":'col-md-6',"id":(_vm.id + "-revenuesTypeDefaultPrice"),"value":_vm.revenuesType.revenuesTypeDefaultPrice,"title":_vm.$t('general.defaultPrice'),"imgName":'money.svg'},on:{"changeValue":function($event){_vm.revenuesType.revenuesTypeDefaultPrice = $event}}}),_c('TextArea',{attrs:{"className":'col-md-12',"id":(_vm.id + "-revenuesTypeNotes"),"value":_vm.revenuesType.revenuesTypeNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.revenuesType.revenuesTypeNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }